<template>
  <div class="Error">
    <b-card class="text-center">
      <div class="" align="center">
        <h2>You got error....</h2>
      </div>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Error',
  components: {
    // HelloWorld
  },
}
</script>

<style>
.Rules{
  margin-left : 3pt;
	margin-right: 6pt;
}
</style>